import {apiClient} from '../http/query-client';

export function getJsApiTicket(): Promise<Response> {
  return apiClient.get('wechat/jsapi_ticket').then(response => response.data);
}

export function wxCallback(code: any): Promise<Response> {
  return apiClient.get('wechat/callback', {params: {code}});
}

export function wxLogin(openid) {
  return apiClient.post('wechat/login', {body: {openid}});
}
