import {useSearchParams} from 'react-router-dom';
import {useForm} from 'react-hook-form';
import {FormTextField} from '../../ui/forms/input-field/text-field/text-field';
import {Button} from '../../ui/buttons/button';
import {Form} from '../../ui/forms/form';
import {RegisterPayload, useUpdateUserInfo} from '../requests/use-register';
import {AuthLayout} from './auth-layout/auth-layout';
import {Trans} from '../../i18n/trans';
import {StaticPageTitle} from '../../seo/static-page-title';
import {useBootstrapData} from '../../core/bootstrap-data/bootstrap-data-context';
import {useAuth} from '../use-auth';
import {Navigate} from 'react-router-dom';
import {message} from 'antd';

export function ImproveAccount() {
  const {invalidateBootstrapData, ...rest} = useBootstrapData();

  const [searchParams] = useSearchParams();

  const form = useForm<RegisterPayload>({
    defaultValues: {},
  });

  let heading = <Trans message="完善账户信息" />;

  console.log(rest, 'rest');

  const invalid = ({real_name, mobile}) => {
    rest.data.user.real_name = real_name;
    rest.data.user.mobile = mobile;
  };

  const updateUserInfo = useUpdateUserInfo(form);

  return (
    <AuthLayout heading={heading} message={''}>
      <StaticPageTitle>
        <Trans message="Login" />
      </StaticPageTitle>
      <Form
        form={form}
        onSubmit={async payload => {
          const {real_name, mobile} = payload as any;

          if (real_name.length < 2) {
            return message.error('姓名长度不能小于2');
          }

          const phoneReg = /^1(3|4|5|6|7|8|9)\d{9}$/;

          if (!phoneReg.test(mobile)) {
            return message.error('手机号格式不合法');
          }
          invalid(payload as any);

          updateUserInfo.mutate(payload);
        }}
      >
        <FormTextField
          className="mb-32"
          name="real_name"
          type="real_name"
          label={<Trans message="真实姓名" />}
          required
        />
        <FormTextField
          className="mb-32"
          name="mobile"
          type="mobile"
          label={<Trans message="手机号" />}
          required
        />

        <Button
          className="block w-full"
          type="submit"
          variant="flat"
          color="primary"
          size="md"
        >
          <Trans message="确定" />
        </Button>
      </Form>
    </AuthLayout>
  );
}

export const ImproveWrapper = ({children}) => {
  const {isLoggedIn, user} = useAuth();

  const isShared = 'drive/s/';

  if (isLoggedIn && !user?.mobile && !location.href.includes(isShared)) {
    return <Navigate to="/improve" replace />;
  }

  return children;
};
