import {useMutation} from '@tanstack/react-query';
import {UseFormReturn} from 'react-hook-form';
import {BackendResponse} from '../../http/backend-response/backend-response';
import {onFormQueryError} from '../../errors/on-form-query-error';
import {useNavigate} from '../../utils/hooks/use-navigate';
import {apiClient} from '../../http/query-client';
import {useAuth} from '../use-auth';
import {useBootstrapData} from '../../core/bootstrap-data/bootstrap-data-context';
import {useCallback, useState, useEffect} from 'react';
import {useAsync, useAsyncFn} from 'react-use';
import WXSDK from '../../wx/lib';

interface LoginResponse extends BackendResponse {
  bootstrapData: string;
  two_factor: false;
}
interface TwoFactorResponse {
  two_factor: true;
}

type Response = LoginResponse | TwoFactorResponse;

export interface LoginPayload {
  email: string;
  password: string;
  remember: boolean;
  token_name: string;
}

export function useLogin(form: UseFormReturn<LoginPayload>) {
  const handleSuccess = useHandleLoginSuccess();
  return useMutation(login, {
    onSuccess: response => {
      if (!response.two_factor) {
        handleSuccess(response as any);
      }
    },
    onError: r => onFormQueryError(r, form),
  });
}

export function useHandleLoginSuccess() {
  const navigate = useNavigate();
  const {getRedirectUri} = useAuth();
  const {setBootstrapData} = useBootstrapData();

  return useCallback(
    (response: LoginResponse) => {
      setBootstrapData(response.bootstrapData);

      const SHAREDLINK = localStorage.getItem('SHAREDLINK');

      if (!SHAREDLINK) {
        navigate(getRedirectUri(), {replace: true});
      } else {
        localStorage.removeItem('SHAREDLINK')
        location.href = SHAREDLINK;
      }
    },
    [navigate, setBootstrapData, getRedirectUri]
  );
}

function login(payload: LoginPayload): Promise<Response> {
  return apiClient.post('auth/login', payload).then(response => response.data);
}

export const useGetWxQrcode = () => {
  return useAsync(async () => {
    const resp = await apiClient.get('wechat/qrcode');

    return resp.data;
  }, []);
};

export const useQrcodeLoginStatus = (randCode: string) => {
  const handleSuccess = useHandleLoginSuccess();

  const [pollState, poll] = useAsyncFn(async () => {
    if (!randCode) return;

    const result = await apiClient.post('wechat/qrcode_status', {
      rand_code: randCode,
    });

    if (result?.data?.bootstrapData) {
      return handleSuccess(result?.data as any);
    }

    return result?.data;
  }, [randCode]);

  useEffect(() => {
    const timer = setInterval(() => poll(), 2000);

    return () => clearInterval(timer);
  }, [randCode]);

  return pollState;
};

export const useAutoWxLogin = () => {
  const handleSuccess = useHandleLoginSuccess();
  return async code => {
    const result: any = await apiClient.get('wechat/callback', {
      params: {code},
    });

    const openId = result?.data?.open_id;

    const response = await apiClient.post('wechat/login', {openid: openId});

    handleSuccess(response?.data);
  };
};
