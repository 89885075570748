import wxSdk from './sdk';
import {getJsApiTicket, wxCallback, wxLogin} from './wxHooks';
import sha1 from 'js-sha1';

const isDev = false;

const img = `${location.origin}/images/logo.png`;
// 'https://www.720cad.com/storage/branding_media/4005b80b-40ce-4eaa-9103-5fdab87b0b50.png';
class WXSDK {
  static instance = new WXSDK();
  get wx() {
    return (window as any).wx;
  }

  ready = false;

  static appId = 'wxa9de86bde4106aee';
  signature = '';
  timestap = 0;

  static async generateSign(timestap) {
    const url = location.href.split('#')[0];

    if (isDev) {
      const template = `jsapi_ticket=LIKLckvwlJT9cWIhEQTwfAscFYvNzkPt55KEjwirGLOfPqdqwd8BvOb59Ye3vUUn_OL54OKqccDXFEHwER52Yw&noncestr=Wm3WZYTPz0wzccnW&timestamp=${timestap}&url=${url}`;

      return (sha1 as any).update(template).hex();
    }

    const jsapi_ticket: any = await getJsApiTicket();

    const template = `jsapi_ticket=${jsapi_ticket.ticket}&noncestr=Wm3WZYTPz0wzccnW&timestamp=${timestap}&url=${url}`;

    return (sha1 as any).update(template).hex();
  }

  task = [];

  constructor() {
    this.callHandle = this.callHandle.bind(this);
  }

  async init() {
    if (!this.isInWxEnvironment()) return;

    if (this.ready) return;

    if (!(window as any).wx) {
      try {
        wxSdk();
      } catch (err) {
        console.error(err);
      }
    }

    if (!(window as any).wx) {
      console.error('微信SDK初始化失败');
      return;
    }

    this.timestap = parseInt((Date.now() / 1000) as any);

    const sign = await WXSDK.generateSign(this.timestap);

    this.signature = sign;

    await this.configure();
  }

  isInWxEnvironment() {
    return /MicroMessenger/i.test(window.navigator.userAgent);
    // return (
    //   typeof (window as any).wx !== 'undefined' ||
    //   typeof (window as any).WeixinJSBridge !== 'undefined'
    // );
  }

  configure() {
    this.wx.config({
      // debug: true, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
      appId: WXSDK.appId, // 必填，公众号的唯一标识
      timestamp: this.timestap, // 必填，生成签名的时间戳
      nonceStr: 'Wm3WZYTPz0wzccnW', // 必填，生成签名的随机串
      signature: this.signature, // 必填，签名
      jsApiList: [
        'checkJsApi',
        'hideMenuItems',
        'updateAppMessageShareData',
        'updateTimelineShareData',
        'hideAllNonBaseMenuItem',
        'login',
      ], // 必填，需要使用的JS接口列表
      error: err => console.error(err),
    });

    this.wx.error(function (res) {
      console.log('config出错:', res);
    });

    const that = this;

    return new Promise((res, rej) => {
      this.wx.ready(function () {
        this.ready = true;

        if (that.task?.length) {
          console.log('延迟调用');

          that.task.forEach(f => f());
        }

        res(true);
      });
    });
  }

  callHandle(fn, args = []) {
    const bindFn = fn.bind(this);

    if (!this.ready) {
      console.log('WX NOT READY');

      this.task.push(() => {
        bindFn(...args)?.catch?.(err => console.error(err));
      });
      return;
    }

    try {
      bindFn(...args)?.catch?.(err => console.error(err));
    } catch (err) {
      console.error('调用失败');
    }
  }

  hideMenuItems() {
    this.wx.hideMenuItems({
      menuList: [
        'menuItem:share:appMessage',
        'menuItem:share:timeline',
        'menuItem:share:qq',
        'menuItem:share:weiboApp',
        'menuItem:favorite',
        'menuItem:share:facebook',
        'menuItem:share:QZone',
      ], // 需要隐藏的菜单项，分享给朋友和分享到朋友圈
    });

    this.wx.hideAllNonBaseMenuItem();
  }

  /**
   * 分享
   * @param param0
   * @returns
   */
  sharedPage({title, desc, link, imgUrl}) {
    return new Promise((res, rej) => {
      this.wx.updateAppMessageShareData({
        title, // 分享标题
        desc, // 分享描述
        link, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
        imgUrl: img, // 分享图标
        success: d => {
          res(d);
          console.log('设置分享成功');
        },
        error: e => {
          rej(e);
          console.error('设置分享失败');
        },
      });
    });
  }

  login(redirectPath?) {
    const redirectUrl = encodeURIComponent(
      `${location.origin}${redirectPath || '/login'}`
    );

    location.href = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${WXSDK.appId}&redirect_uri=${redirectUrl}&response_type=code&scope=snsapi_base&state=STATE#wechat_redirect`;
    // return new Promise((resolve, rej) => {
    //   this.wx.login({
    //     success: async function (res) {
    //       if (res.code) {
    //         // 将 res.code 发送到后端服务器

    //         const result = await wxCallback(res.code)
    //           .then(response => response.json())
    //           .then(data => {
    //             const {open_id} = data;

    //             return wxLogin(open_id);
    //           })
    //           .catch(error => {
    //             console.error('请求后端登录接口失败:', error);
    //           });

    //         resolve(result);
    //       } else {
    //         console.log('登录失败：', res);
    //         rej(res);
    //       }
    //     },
    //     fail: function (error) {
    //       rej(error);
    //       console.error('微信登录失败：', error);
    //     },
    //   });
    // });
  }
}

WXSDK.instance.init();

export default WXSDK;
